const backendApiBaseUrl = `https://backend-api-dev.dms.malogica.com`;
const nuxeoBaseUrl = `https://api-dev-v2.dms.malogica.com/nuxeo`;

export const environment = {
  production: true,
  // nuxeoURL: `${nuxeoBaseUrl}/site/api/v1`,
  // nuxeoOldURL: `${nuxeoBaseUrl}/api/v1`,
  nuxeoURL: `${backendApiBaseUrl}/api/nuxeo/site/api/v1`,
  nuxeoOldURL: `${backendApiBaseUrl}/api/nuxeo/api/v1`,
  nuxeoApiUrl: `${backendApiBaseUrl}/api`,
  newToken: `${backendApiBaseUrl}/renew-token`,
  login: `${backendApiBaseUrl}/login`,
  register: `${backendApiBaseUrl}/register`,
  backendApiNuxeoUrl: `${backendApiBaseUrl}/api/nuxeo/site/api/v1`,
};
