import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  ViewChild,
  signal,
} from '@angular/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DocumentActionsComponent } from '../../../../document-actions/document-actions.component';
import { FormsModule } from '@angular/forms';
import { FileData } from '../../models/file-data';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {
  DocumentEditorContainerComponent,
  DocumentEditorContainerModule,
} from '@syncfusion/ej2-angular-documenteditor';
import { GetDocumentService } from '../../../services/get-document.service';
import { InitializeAndUpload } from '@shared/util/file.util';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { IFileSubmitType } from '../../../models/file-submit-type.model';
import * as moment from 'moment';
import { debounceTime, Subject, tap } from 'rxjs';

@Component({
  selector: '[dms-file]',
  templateUrl: `./file.component.html`,
  imports: [
    DocumentActionsComponent,
    FormsModule,
    AngularEditorModule,
    NgxDocViewerModule,
    DocumentEditorContainerModule,
  ],
  styleUrls: ['./file.component.scss'],
  standalone: true,
})
export class FileComponent {
  private _fileData: FileData;
  src: string;
  @ViewChild('editorContainer')
  editorContainer: DocumentEditorContainerComponent;
  @ViewChild('editorContainerDialog')
  editorContainerDialog: DocumentEditorContainerComponent;
  @ViewChild('fileDialog')
  fileDialog: ElementRef;
  lastTimeSaved = signal('');

  @Input()
  set fileData(value: FileData) {
    if (value) {
      this._fileData = value;
      if (!this.isLocalSave) {
        this.processFileData();
      }
    }
  }

  get fileData(): FileData {
    return this._fileData;
  }

  submit = new EventEmitter<IFileSubmitType>();
  isLocalSave: boolean;
  isSaving = false;
  private isDebounceActive = false;
  private saveSubject = new Subject<void>();

  constructor(
    private service: GetDocumentService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef

  ) {}

  processFileData(editorContainer?: any) {
    if (this.fileData) {
      this.service.getDocxFuleById(this.fileData.id).subscribe((file: any) => {
        this.lastTimeSaved.set(
          moment(file.extraProperties.documentProperties.properties['dc:modified'])
            .utc()
            .fromNow(),
        );
        if ('sfdt' in file && typeof file.sfdt === 'string' && typeof file.sfdt.length) {
          if (editorContainer) {
            editorContainer.documentEditor.open(file.sfdt as string);
          } else {
            this.editorContainer.documentEditor.open(file.sfdt as string);
          }
          this.src = file.sfdt as string;
        } else {
          // this.src = (file as any)?.extraProperties?.documentProperties?.properties[
          //   'file:content'
          // ]?.data;

          this.src = ``;
          console.log(`SFDT IS NULL`);
          // this.alertService.setAlert(
          //   new Alert('Wrong Format', `This format is not supported`, 'error'),
          // );
        }
      });
    }
  }

  onContentChange(_: any) {
    if (!this.isDebounceActive) {
      this.isDebounceActive = true;
      this.saveSubject
        .pipe(
          tap(() => (this.isSaving = true)),
          debounceTime(3500),
        )
        .subscribe(() => {
          this.isSaving = false;
          this.saveDocument();
        });
    }

    this.saveSubject.next();
  }

  private saveDocument() {
    this.isLocalSave = true;
    this.saveAsBlob();
  }

  public saveAsBlob() {
    const documentEditor = this.editorContainer.documentEditor;
    const documentEditorContainerDialog = this.editorContainerDialog.documentEditor;
    const editor = this.fileDialog.nativeElement.open
      ? documentEditorContainerDialog
      : documentEditor;

    editor
      .saveAsBlob('Docx')
      .then((blob: Blob) => {
        const blobToBase64 = (blob: Blob) =>
          new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });

        blobToBase64(blob).then((base64String: string) => {
          const validBase64 = base64String.split(',')[1];
          this.submitFile(validBase64);
          this.lastTimeSaved.set(
            moment(this._fileData?.currentDocument?.properties?.['dc:modified'])
              .utc()
              .fromNow(),
          );
        });
      })
      .catch(error => {
        console.error('Error saving document:', error);
      });
  }

  updateFile(event) {
    const file = event.target.files[0];
    if (file) {
      InitializeAndUpload(file, this.submitFile);
    }
  }

  submitFile = (base46: string) => {
    this.submit.emit({ file: base46, isLocalSave: this.isLocalSave });

    if (this.isLocalSave) {
      this.isLocalSave = false;
    }
  };

  /**
   * Enables copy-paste functionality both within the editor and between the editor and external applications.
   * Overrides the default `copy` behavior to utilize the global clipboard.
   * Retained as a reference for potential future use if extended clipboard functionality is required.
  
    enableLocalPaste = signal(false);

    onEditorCreated() {
      const editor = this.editorContainer.documentEditor;

      editor.selection.copy = () => {
        this.enableLocalPaste.set(true); // Update signal value
        const selectedText = editor.selection.text;

        if (selectedText) {
          navigator.clipboard.writeText(selectedText).catch((err) => {
            console.error('Failed to copy text to clipboard:', err);
          });
        }

        setTimeout(() => {
          this.enableLocalPaste.set(false); // Reset signal value
        }, 100);
      };
    }
   **/
  
  public detectChanges() {
    this.processFileData();
    this.cdr.detectChanges();
  }
}
