import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { Alert } from '../../models/alert.model';
import { AuthService } from 'src/app/auth/componentss/services/auth.service';
import { LoginService } from 'src/app/auth/componentss/services/login.service';
import { FusionAuthService } from 'src/app/auth/componentss/services/fusion-auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class NuxeoAuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private alertService: AlertService,
    private auth: AuthService,
    private fusionAuth: FusionAuthService,
    private loginService: LoginService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isNuxeoReq =
      req.url.startsWith(environment.nuxeoURL) || req.url.startsWith(environment.nuxeoOldURL);

    const authReq = req.clone({
      setHeaders: {
        'content-type': req.headers.get('content-type') ?? 'application/json',
        Authorization: isNuxeoReq
          ? 'Basic ' + btoa(`${this.auth.account}`)
          : 'Bearer ' + this.auth.fusionToken,
      },
    });

    return next.handle(authReq).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.handleUnauthorizedError(err);
            return throwError(() => new Error('Unauthorized'));
          } else if (err.status === 403) {
            if (this.isRefreshing) {
              return throwError(() => new Error('Token refresh in progress.'));
            }

            this.isRefreshing = true;

            return this.fusionAuth.getRefreshToken().pipe(
              switchMap(refreshToken => {
                this.isRefreshing = false;

                localStorage.setItem('fusion_token', refreshToken.access_token);
                localStorage.setItem('token', refreshToken.access_token);

                const newAuthReq = req.clone({
                  setHeaders: {
                    Authorization: 'Bearer ' + refreshToken.access_token,
                  },
                });

                return next.handle(newAuthReq);
              }),
              catchError(_ => {
                this.isRefreshing = false;
                this.loginService.logOut();
                this.alertService.setAlert(
                  new Alert('Session Expired', 'Please log in again.', 'error'),
                );
                return throwError(() => new Error('Failed to refresh token'));
              }),
            );
          } else if (err.error?.message) {
            this.alertService.setAlert(new Alert('Error', `${err.error.message}`, 'error'));
            return throwError(() => err);
          }
        }
        return throwError(() => err);
      }),
    );
  }

  private handleUnauthorizedError(err: HttpErrorResponse) {
    if (
      typeof err.error === 'object' &&
      'parameters' in err.error &&
      err.error.parameters.message
    ) {
      const message = (err.error.parameters as any).message;
      this.alertService.setAlert(
        new Alert('Permission Denied', `${message}`, 'error'),
      );
    } else {
      this.alertService.setAlert(
        new Alert(`${err.error.title}`, 'Try to log in again', 'error'),
      );
      this.loginService.logOut();
    }
  }
}

