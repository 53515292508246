import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { dummyAction } from '@state/app.actions';
import { Manage } from '@state/manage/manage.action';
import { takeDocumentsList, takeMenuTree } from './manage.selector';
import { currentRoute } from '@state/router-state/router.selector';
import { Router } from '@angular/router';
import { toggleFolder } from '@routes/manage/util/manage.util';
import { IDocumentEntity } from '@shared/models/document.model';
import { SearchDocumentService } from '@routes/manage/services/search-document.service';
import { SourceDataServiceLastet } from '@routes/manage/services/source-data-service.service';
import { removeCustomPaths } from '@routes/manage/util/manage-routing.util';
import { LoaderService } from '@shared/components/loader/loader.service';
import * as moment from 'moment';
import { takeDisplayDataPagination } from '@state/displayed-data/displayed-data.selector';
import { GetDocumentService } from '@routes/home/components/document-details/components/view-document/services/get-document.service';
import { containsUUID } from 'src/app/util/contains-uuid.util';

@Injectable()
export class ManageEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private searchDocumentService: SearchDocumentService,
    private getDocumentService: GetDocumentService,
    private sourceDataService: SourceDataServiceLastet,
    private loader: LoaderService,
  ) {}

  loadManageInitialData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Manage.loadDataByPath),
      withLatestFrom(
        this.store.select(currentRoute),
        this.store.select(takeDisplayDataPagination),
      ),
      mergeMap(([{ path, navigateByDisplayPath, noNavigate }, route, displayData]) => {
        const isManageRoute = route.url.slice(1, 7) === 'manage';
        const documentPath = path !== undefined ? path : removeCustomPaths(route.url);

        if (isManageRoute) {
          this.loader.setLoading({ key: 'manage-workspace', status: true });

          const normalizedPath = documentPath === '/' ? '' : documentPath.slice(1);

          if (containsUUID(normalizedPath)) {
            return this.loadInitialData(
              '/',
              displayData,
              path,
              navigateByDisplayPath,
              noNavigate,
            );
          } else {
            return this.loadInitialData(
              normalizedPath,
              displayData,
              path,
              navigateByDisplayPath,
              noNavigate,
            );
          }
        } else {
          this.loader.setLoading({ key: 'manage-workspace', status: false });
          return of(dummyAction());
        }
      }),
    ),
  );

  toggleFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Manage.toggleFolder),
      withLatestFrom(this.store.select(takeMenuTree)),
      mergeMap(([{ node, documentsList }, menuTree]) => {
        if (!documentsList?.length) {
          return this.searchDocumentService.getDocumentsByParentId(node.uid).pipe(
            map(documentsList => ({ node, documentsList })),
            map(({ node, documentsList }) => {
              let _node = { ...node };
              if (!documentsList.length) {
                _node = { ...node, emptyFolder: true };
              }
  
              const updatedMenuTree = toggleFolder({
                node: _node,
                menuTree,
                documentsList,
              });
  
              return Manage.toggleFolderSuccess({ menuTree: updatedMenuTree });
            }),
            catchError((error) => {
              console.error('Error in toggleFolder:', error);
              return of(dummyAction());
            })
          );
        } else {
          const updatedMenuTree = toggleFolder({
            node,
            menuTree,
            documentsList,
          });
  
          return of(
            Manage.toggleFolderSuccess({
              menuTree: updatedMenuTree,
            }),
          );
        }
      }),
      catchError((error) => {
        console.error('Unhandled error in toggleFolder$:', error);
        return of(dummyAction()); 
      })
    ),
  );
  

  filterActiveDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Manage.filterActiveDocumentList),
      withLatestFrom(this.store.select(takeDocumentsList)),
      mergeMap(([{ range }, documentsList]) => {
        if (range) {
          const { start, end } = range;
          if (start && end) {
            this.loader.setLoading({ key: 'manage-workspace', status: true });

            const filteredDocumentsList = documentsList.filter(item => {
              if (item.lastModified && moment(item.lastModified).isValid()) {
                const itemDate = moment(item.lastModified, true);
                return itemDate.isBetween(start, end, null, '[]');
              } else {
                return null;
                // this.alert....
              }
            });

            this.loader.setLoading({ key: 'manage-workspace', status: false });
            return of(
              Manage.filterActiveDocumentListSuccess({ filteredDocumentsList, range }),
            );
          } else {
            console.clear();
            return of(Manage.loadDataByPath({}));
          }
        } else {
          this.loader.setLoading({ key: 'manage-workspace', status: false });
          return of(/* some default action or value */);
        }
      }),
    ),
  );

  setActiveDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Manage.setActiveDocumentsList),
      mergeMap(({ menu }) => {
        if (menu) {
          this.loader.setLoading({ key: 'manage-workspace', status: true });
          return this.getDocumentService.getDocxFuleById(menu.uid).pipe(
            switchMap((currentDocument: any) =>
              this.searchDocumentService.getDocumentsByParentId(menu.uid).pipe(
                map((documentsList: IDocumentEntity[]) => {
                  if (documentsList?.length) {
                    if (currentDocument.displayPath === '/Root') {
                      this.router.navigate([decodeURI(`manage/`)]);
                    } else {
                      this.router.navigate([decodeURI(`manage/${currentDocument.path}`)]);
                    }
                  }
      
                  this.store.dispatch(
                    Manage.toggleFolder({
                      node: menu,
                      documentsList,
                    }),
                  );
      
                  this.loader.setLoading({ key: 'manage-workspace', status: false });
                  return Manage.setActiveDocumentsListSucccess({
                    documentsList,
                    currentDocument,
                  });
                })
              )
            ),
            catchError((error) => {
              console.error('Error in manage workspace flow:', error);
              this.loader.setLoading({ key: 'manage-workspace', status: false });
              return of(dummyAction());
            })
          );
        } else {
          this.loader.setLoading({ key: 'manage-workspace', status: false });
          return of(dummyAction());
        }
      }),
    ),
  );

  private loadInitialData(
    documentPath: string,
    displayData: any,
    path: any,
    navigateByDisplayPath: any,
    noNavigate: any,
  ) {
    console.log(documentPath, 'path path path');
    return this.sourceDataService.initialData(documentPath, displayData).pipe(
      map(({ menuTree, documentsList, currentDocument }) => {
        if (path !== undefined && !navigateByDisplayPath && !noNavigate) {
          this.router.navigate([`manage/${decodeURI(path)}`]);
        }
        this.loader.setLoading({ key: 'manage-workspace', status: false });
        return Manage.loadDataByPathSuccess({
          menuTree,
          documentsList,
          currentDocument,
        });
      }),
      catchError(err => {
        this.loader.setLoading({ key: 'manage-workspace', status: false });
        console.log(err);
        console.log('Wrong Path!');
        return of(dummyAction());
      }),
    );
  }
}
